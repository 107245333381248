<!-- =========================================================================================
    File Name: forgetPassword.vue
    Description: forget password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div
    class="flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-forgetPass"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img
                src="@/assets/images/pages/Biadjo Vectors_ForgetPass.png"
                alt="forgetPassword"
                class="mx-auto"
              />
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="px-8 pt-8 login-tabs-container">
                <div class="vx-card__title">
                  <vs-button
                    type="border"
                    to="/user/login/login"
                    class="px-4 md:w-auto float-right mt-2"
                    >{{ $t("BackToLogin") }}</vs-button
                  >
                  <h4 class="mb-4">{{ $t("ForgotPasswordPage") }}</h4>
                  <p>{{ $t("ForgotPasswordPageTitle") }}</p>
                </div>
                <ForgetPasswordJWT></ForgetPasswordJWT>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import ForgetPasswordJWT from "./forgetPasswordJWT.vue";

export default {
  components: {
    ForgetPasswordJWT
  }
};
</script>

<style lang="scss">
.login-tabs-container {
  min-height: 455px;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}
</style>
